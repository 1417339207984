import React from "react";
import { Segment, Grid, Icon, Popup } from "semantic-ui-react";
import "../stylesheets/pages.css";
import { skillsObj } from "../data/skills";

function Skills() {

  const createSkillRating = (value, name) => {
    const circleInputs = [];
    for (let i = 0; i < value; i++) {
      circleInputs.push(
        <Icon name="circle" key={"circle" + i + name} className="tealStyle" />
      );
    }
    for (let i = 0; i < 5 - value; i++) {
      circleInputs.push(
        <Icon
          name="circle outline"
          key={"empty circle" + i + name}
          className="tealStyle"
        />
      );
    }
    return circleInputs;
  };

  const generateSkillRow = (name, value) => {
    return (
      <Grid columns={2} key={name}>
        <Grid.Column className="lightGreyStyle">{name}</Grid.Column>
        <Grid.Column>{createSkillRating(value, name)}</Grid.Column>
      </Grid>
    );
  };

  return (
    <Segment className="pageStyle">
      <Grid stackable columns={2}>
        <Grid.Column>
          <h3 className="subTitleStyle">Languages</h3>
          <br />
          {skillsObj.languages.map(language => generateSkillRow(language.name, language.value))}
        </Grid.Column>
        <Grid.Column>
          <h3 className="subTitleStyle">Technologies</h3>
          <br />
          {skillsObj.technologies.map(technology => generateSkillRow(technology.name, technology.value))}
        </Grid.Column>
      </Grid>
      <div
        style={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        <Popup
          trigger={<Icon
            className="lightGreyStyleLink" name="question circle" size="large" />}
          position="left center"
          wide
        >
          <p>Proficiency Levels:</p>
          <p>0: No knowledge, I have never used this skill.</p>
          <p>1: Basic, I have used this skill a couple of times, I need more time and projects to feel comfortable using it daily.</p>
          <p>2: Novice, I am generally comfortable with using this skill occasionally; I rely heavily on documentation and forums for most concepts.</p>
          <p>3: Intermediate, I am comfortable using this skill daily, I rely on documentation for some concepts.</p>
          <p>4: Advanced, I am confident in my projects that incorporate this skill, I rarely need documentation for most concepts.</p>
          <p>5: Expert, I am extremely confident using this skill and I contribute my knowledge to the rest of the community of professionals that use it.</p>
        </Popup>
      </div>
    </Segment>
  );
}

export default Skills;
