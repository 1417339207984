import React from "react";
import { Icon, Segment, Grid, Image } from "semantic-ui-react";
import "../stylesheets/pages.css";

function About() {
  return (
    <Segment className="pageStyle">
      <div>About Me</div>
    </Segment>
  );
}

export default About;
