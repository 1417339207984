import React from "react";
import { Icon, Segment, Grid, Image, Header } from "semantic-ui-react";
import "../stylesheets/pages.css";
const profilePic = require("../img/profile.jpg");

function Home() {
  return (
    <Segment className="pageStyle">
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={8}
            style={{
              textAlign: "right",
            }}
          >
            <Grid.Row>
              <Header size='huge' style={{ color: "#2aa2a7", fontFamily: 'Playfair Display' }}>
                Alexandra Strojny
                <Header.Subheader style={{ color: "#f88379" }}><br />
                  <b>Full Stack Software Engineer</b>
                </Header.Subheader>
              </Header>
              <h2
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  marginRight: "8px"
                }}
              >
                <a
                  href="mailto:alexandrastrojny@gmail.com"
                  className="lightGreyStyleLink"
                >
                  <Icon name="mail" />
                </a>
                <a
                  href="https://github.com/alexandra-nero"
                  className="lightGreyStyleLink"
                >
                  <Icon name="github" />
                </a>
                <a
                  href="https://www.linkedin.com/in/neroalexandra/"
                  className="lightGreyStyleLink"
                >
                  <Icon name="linkedin" />
                </a>
              </h2>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src={profilePic} size="medium" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

export default Home;
