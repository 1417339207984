export const experienceObjects = [
  {
    title: "Senior Software Engineer, Toast",
    date: "May 2022 - Present",
    tasks: [
      "Coordinates with product manager and UX designers to design and implement features that assist restaurants in managing and viewing employee schedules",
      "Maintains and triages Apache Camel and DropWizard Services"
    ],
    skill: "How to utilize GraphQL"
  },
  {
    title: "Software Engineer III, Cimpress Technology",
    date: "November 2018 - May 2022",
    tasks: [
      "Develop and maintain a series DropWizard and Express microservices that power the order processing platform used by Cimpress businesses",
      "Design and prioritize features in an agile setting with weekly sprints"
    ],
    skill: "How to set up and use pipelines that automatically deploy changes from git to AWS using serverless"
  },
  {
    title: "Software Engineer, Hitachi Vantara",
    date: "October 2017 - November 2018",
    tasks: [
      "Participated in high level design of the file storage product, “HCP Anywhere”",
      "Designed and developed reliable infrastructure to test Java APIs, a Windows Client, and a Windows Server service application"
    ],
    skill: "The importance of robust testing and standard JUnit practices"
  },
  {
    title: "Software Developer Intern, Lattice Automation, Inc.",
    date: "June 2017 - October 2018",
    tasks: [
      "Developed a single page web application that aids engineers in creating and implementing synthetic biology experiments.",
      "Enabled web application components to query back-end information in MongoDB with GraphQL."
    ],
    skill: "How to develop a responsive single page application using ReactJS"
  },
  {
    title: "Summer Software Intern, Nona Research Foundation",
    date: "June 2016 - August 2016",
    tasks: [
      "Programmed use cases in Java and JavaScript and contributed to documentation for the database software, “Clotho”.",
      "Delivered a presentation at “Nona Talks” about the state of software support and maintenance in synthetic biology."
    ],
    skill: "The basics of RESTful APIs and how to utilize them"
  },
  {
    title: "Student, Boston University College of Engineering",
    date: "August 2013 - May 2017",
    description: "Received a Bachelor of Science degree in Biomedical Engineering and a Bachelor of Science degree in Computer Engineering."
  },
]