export const skillsObj  = {
  languages: [
    {
      name: "Javascript",
      value: 4
    },
    {
      name: "Typescript",
      value: 4
    },
    {
      name: "Java",
      value: 3
    },
    {
      name: "Python",
      value: 3
    },
    {
      name: "SQL",
      value: 2
    },
    {
      name: "GoLang",
      value: 1
    },
    {
      name: "C++",
      value: 2
    },
    {
      name: "C#",
      value: 2
    },
    {
      name: "HTML/CSS",
      value: 3
    }
  ],
  technologies: [
    {
      name: "React", 
      value: 4
    },
    {
      name: "AWS", 
      value: 3
    },
    {
      name: "Elastic Search", 
      value: 2
    },
    {
      name: "MongoDB", 
      value: 2
    },
    {
      name: "Git", 
      value: 3
    }
  ]
}