import React from "react";
import { Timeline } from "antd";
import { Segment } from "semantic-ui-react";
import {experienceObjects} from "../data/experience";
import "../stylesheets/pages.css";

function Experience() {

  const generateTimelineItem = (experienceObj) => {
    return (
      <Timeline.Item color="#2aa2a7" label={experienceObj.date} key={experienceObj.title}>
        <p className="subTitleStyle">
          <b>{experienceObj.title}</b>
        </p>
        <div className="lightGreyStyle">
          {experienceObj.description ?
            (<ul><li>{experienceObj.description}</li></ul>) :
            (<>
              <p className="tealStyle"><b>Daily Tasks:</b></p>
              <ul>{experienceObj.tasks.map(task => (<li key={task}>{task}</li>))}</ul>
              <br />
              <p className="tealStyle"><b>Favorite Skill I Learned:</b></p>
              <p>{experienceObj.skill}</p>
            </>)
          }
        </div>
      </Timeline.Item>
    );
  }

  return (
    <Segment className="pageStyle">
      <Timeline mode="left">
        {experienceObjects.map(experienceObj => generateTimelineItem(experienceObj))}
      </Timeline>
    </Segment>
  );
}

export default Experience;
