import React from "react";
import { Segment, List } from "semantic-ui-react";
import "../stylesheets/pages.css";
import { projectsObj } from "../data/projects";

function Projects() {
  
  const returnProject = (projectObj) => {
    return (
      <List.Item key={projectObj.title}>
        <List.Content>
          <List.Header as="a">
            {<h5 className="tealStyle">{projectObj.title}</h5>}
          </List.Header>
          <List.Description as="a">
            <div className="lightGreyStyle">
              <ul>
                {projectObj.tasks.map(task => (<li key={task}>{task}</li>))}
              </ul>
            </div>
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }

  return (
    <Segment className="pageStyle">
      <h3 className="subTitleStyle">Personal Projects</h3>
      <List>
        {projectsObj.personal.map(project => returnProject(project))}
      </List>
      <h3 className="subTitleStyle">University Projects</h3>
      <List>
        {projectsObj.college.map(project => returnProject(project))}
      </List>
    </Segment>
  );
}

export default Projects;
