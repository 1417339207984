import React, { useState, useEffect } from "react";
import { Menu } from "semantic-ui-react";
import Home from "./pages/Home";
import About from "./pages/About";
import Experience from "./pages/Experience";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills";
import "./stylesheets/App.css";
var get = require('lodash.get');

const itemStyle = {
  color: "lightgrey",
};

const itemHoverStyle = {
  color: "#f88379",
  borderBottomColor: "#f88379",
};

const itemClickStyle = {
  color: "#2aa2a7",
  borderBottomColor: "#2aa2a7",
};

const menuItems = [{ id: "home" }, { id: "experience" }, { id: "projects" }, { id: "skills" }];

function App() {
  const [current, setCurrent] = useState(menuItems[0].id);
  const [hover, setHover] = useState("");
  const refs = menuItems.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    const onScroll = e => {
      const currentLoc = e.target.scrollingElement.scrollTop;
      const middleOfTheScreen = window.innerHeight / 2 + currentLoc;
      let idToUpdate = menuItems[0].id;
      menuItems.forEach(item => {
        const itemTopPosition = get(refs[item.id], 'current.offsetTop');
        if (itemTopPosition) {
          if (middleOfTheScreen > itemTopPosition) {
            idToUpdate = item.id;
          }
        }
      });

      //If the screen is too narrow, HOME will never be set as the selected menuItem because
      // it will have such a small height compared to the next item, experience
      const homeCenterLoc = get(refs["home"], 'current.offsetHeight') / 2 + get(refs["home"], 'current.offsetTop');
      if (currentLoc < homeCenterLoc) {
        idToUpdate = "home";
      }
      if (current !== idToUpdate) {
        setCurrent(idToUpdate);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [current, refs]);

  const toggleHover = (name) => {
    setHover(name);
  };

  const toggleOffHover = () => {
    setHover("");
  };

  const returnStyle = (name) => {
    if (name === hover) {
      return itemHoverStyle;
    } else if (name === current) {
      return itemClickStyle;
    } else {
      return itemStyle;
    }
  };

  const returnSize = () => {
    const width = window.innerWidth;
    if (width < 300){
      return "mini"
    }
    if (width < 400){
      return "tiny"
    }
    else if (width < 500){
      return "small"
    } else {
      return "large";
    }
  }

  const handleClick = (id) => {
    refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setCurrent(id);
  }

  return (
    <div>
      <Menu pointing secondary fixed="top" size={returnSize()} className="menuStyle">
        {menuItems.map(item => (
          <Menu.Item
            name={item.id.toUpperCase()}
            key={item.id}
            onClick={() => handleClick(item.id)}
            style={returnStyle(item.id)}
            onMouseEnter={() => toggleHover(item.id)}
            onMouseLeave={() => toggleOffHover()}
          />
        ))}
      </Menu>

      <div
        style={{ marginTop: "20px", marginBottom: "50px" }}
      >
        {menuItems.map(item => (
          <div
            key={item.id}
            ref={refs[item.id]}
            style={{ paddingTop: "20px" }}
          >
            {item.id === "home" && <Home />}
            {item.id === "about" && <About />}
            {item.id === "experience" && <Experience />}
            {item.id === "projects" && <Projects />}
            {item.id === "skills" && <Skills />}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
