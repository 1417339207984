export const projectsObj = {
  personal: [
    {
      title: "Cookbook Application",
      technologies: "React, GoLang, MongDB",
      type: "personal",
      tasks: [
        "Designed a single page UI Application to alow users to insert, edit, and search custom cookbook recipes",
        "Stood up a GoLang server to interact with the UI and the MongoDB cluster"
      ],
    },
    {
      title: "Resume Website",
      technologies: "React, Semantic UI, AWS S3",
      type: "personal",
      tasks: [
        "This site!",
        "Created a custom website to allow for more flexible descriptions of my experiences and projects than a standard one page resume provides"
      ],
    },
  ],
  college: [
    {
      title: "College of Engineering Senior Design Project",
      technologies: "",
      tasks: [
        "Designed a library of interconnecting hardware modules to automatically conduct biological experiments on microfluidic chips",
        "Constructed the “PCR” hardware module using an Arduino and a connecting circuit to vary fluid temperature to trigger the duplication of DNA through polymerase chain reaction",
        "Programmed a PID controller in C and designed an interfacing GUI in Node.js and Bootstrap"
      ],
    },
    {
      title: "Advanced Data Structures Final Project",
      technologies: "",
      tasks: [
        "Led a project to implement the programming and design of a music playlist storage application",
        "Employed a front end GUI in Java with efficient back end search and sort algorithms in C++ to store and retrieve relevant songs from the user’s library"
      ]
    },
    {
      title: "Computational Biology Final Project",
      technologies: "",
      tasks: [
        "Devised a final project to computationally identify the probability of regulator/promoter pairs of E. coli DNA using a Naïve Bayesian model coded in Python",
        "Presented the specific aims, timeline, and description of the project in a research proposal"
      ]
    }
  ]
};
